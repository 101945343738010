function setStorageKey(key, data) {
    try {
      localStorage.setItem(key, data);
    } catch(err) {
      console.log(err);
    }
  }
  
  function getStorageKey(key) {
    try {
      return localStorage.getItem(key);
    } catch (err) {
      console.log(err);
    }
  }

  export {
    setStorageKey,
    getStorageKey,
}