import React from "react"
export function ProfileLink(props){
    const spacerStyle = {
        margin:'10px',
    }

    const hrefStyle = {
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        textDecoration:'none'
    }

    const imgStyle = {
        height:props.height,
        width:props.width,
        margin:'3px 10px'
    }

    const linkStyle = {
        color:props.color,
    }

    return (
        <div style={spacerStyle}>
            <a href={props.url} target={props.target} style={hrefStyle}>
                <img src={props.icon} alt={props.alt} style={imgStyle}/>
                {props.nameOn ? <div style={linkStyle}>{props.name}</div> : null}
            </a>
        </div>
    )
}