import React from "react";

export function TextBlock(props){
    
    const textBlockStyle = {
        fontSize: 'calc(10px + 2vmin)',
        width:'70vmin',
        textAlign:'justify',
        margin:'10px',
    }

    return(
        <div style={textBlockStyle}>
            {props.text}
        </div>
    )
}