export function Toggle(props){
    const setOnOff = props.setOnOff;
    const height = props.height;
    const heightSwitch = height+6;
    const width = props.height * 2;
    const boardRadius = width + 2;
    

    const backgroundColor = props.backgroundColor;
    const backgroundBorder = props.backgroundBorder;
    const buttonColor = props.buttonColor;
    const buttonBorder = props.buttonBorder;

    const toggleBackground = {
        width: width+'px',
        height: heightSwitch+'px',
        border:'2px solid '+backgroundBorder+'',
        borderRadius: boardRadius+'px',
        backgroundColor:backgroundColor,
    }

    const toggleButton = {
        height: height+'px',
        width: height+'px',
        borderRadius:'50%',
        border:'2px solid '+buttonBorder+'',
        margin:'1px',
        backgroundColor:buttonColor
    }

    const toggleOn = {
        float:'left',
    }

    const toggleOff = {
        float:'right',
    }

    return(
        <div style={toggleBackground}>
            <div style={setOnOff ? toggleOff :  toggleOn }>
                <div style={toggleButton}>
                </div>  
            </div>
        </div>
    )
}