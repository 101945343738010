import React, {useState, useEffect} from 'react';

import logoW from './assets/logos/logo5.png';
import logoC from './assets/logos/logo6.png';

import InstaLogoW from './assets/icons/instagramLogoW.png';
import InstaLogoB from './assets/icons/instagramLogoB.png';
import EmailIconW from './assets/icons/emailIconW.png';
import EmailIconB from './assets/icons/emailIconB.png';
import FacebookIconW from './assets/icons/facebookIconW.png';
import FacebookIconB from './assets/icons/facebookIconB.png';

import { Toggle } from './components/Toggle';
import {ProfileLink} from './components/ProfileLink';
import { TextBlock } from './components/TextBlock';
import { EmailLink } from './components/EmailLink';
import { setStorageKey, getStorageKey } from './utils/storageHandler';

const yardbroEmail = 'groveolsenco@gmail.com';
const yardbroAbout = `Our mission is to bring a refined yet easygoing style to backyard games. We have created a brand that's recognized for its professional grade cornhole boards. We do this by using quality materials and carefully handcrafting each of our products. Each of our cornhole boards are made to the American Cornhole League specifications. We also have married the element of quality with customization, tailoring our products to fit your style. Providing a platform for friendly competition is our goal. From friends and family, to coworkers and neighbors, we aim to encourage people of all ages and backgrounds to gather together wherever they may be.`;

function App() {
  const [set, isSet] = useState();
  const [theme, setTheme] = useState();// false = dark & true = light

  function updateTheme(theme){
    setStorageKey('@theme',theme);
    setTheme(theme);
  }

  const appStyle = {
    textAlign:'center'
  }

  const headerStyle = {
    backgroundColor: theme ? '#333333' : '#fff',
    minHeight:'100vh' ,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: theme ? '#fff' : 'black',
  }

  const rowStyle = {
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    justifyContent:'center',
    marginBottom:'25px'
  }

  useEffect(()=>{
    if(!set){
      const t = getStorageKey('@theme');
      setTheme(t);
    }
    return isSet(true);
  },[set]);

  return (
    <div style={appStyle}>
      
      <header style={headerStyle}>

        <img src={theme ? logoW : logoC} alt="logo" height="400"/>      
        <div onClick={()=>updateTheme(theme ? false : true)}>
          <Toggle 
            setOnOff={theme} 
            height={20}
            backgroundColor={theme ? '#fff' : '#333333' }
            backgroundBorder={theme ? '#bbb' : '#010100'}
            buttonColor={theme ? '#eee' : '#ea0a2a'}
            buttonBorder={theme ? '#bbb' : '#fec526'}
          />
        </div>
        <TextBlock text={yardbroAbout} />
        <div style={rowStyle}>
          <ProfileLink 
            nameOn={true}
            name={'Instagram'} 
            target={'_blank'} 
            url={'https://www.instagram.com/yardbros.insta'} 
            icon={theme ? InstaLogoW : InstaLogoB}
            alt={'instagram icon'}
            height={40}
            width={40}
            color={theme ? '#fff' : 'black'}
          />
          <ProfileLink 
            nameOn={true}
            name={'Facebook'} 
            target={'_blank'} 
            url={'https://facebook.com/YardBrosMD/'} 
            icon={theme ? FacebookIconW : FacebookIconB}
            alt={'facebook icon'}
            height={40}
            width={40}
            color={theme ? '#fff' : 'black'}
          />
          <EmailLink 
            nameOn={true}
            name={'Email Us'} 
            target={'_blank'} 
            url={'mailto: '+yardbroEmail+'?subject=Corn-Hole-Dream-Team'} 
            icon={theme ? EmailIconW : EmailIconB}
            alt={'email icon'}
            height={40}
            width={64}
            color={theme ? '#fff' : 'black'}
          />
        </div>
        
      </header>
    </div>
  );
}

export default App;
